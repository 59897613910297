<!-- <p-gmap [options]="options" [style]="{'width': '100%', 'height': '50vh'}" [overlays]="overlays"></p-gmap> -->

<agm-map
    [styles]="styles"
    [longitude]="(state.currentCoordinates | async).lng"
    [latitude]="(state.currentCoordinates | async).lat"
    [zoom]="state.zoom | async"
    ><agm-marker-cluster
        [imagePath]="
            'https://github.com/googlearchive/js-marker-clusterer/blob/gh-pages/images/m2.png?raw=true'
        "
        [maxZoom]="15"
        [minimumClusterSize]="6"
        >
            <agm-marker
                *ngFor="let helper of currentHelpersWithLocation | async"
                [latitude]="helper?.location.lat"
                [longitude]="helper?.location.lng"
            >
                <agm-snazzy-info-window
                    [closeWhenOthersOpen]="true"
                    maxHeight="6000"
                    maxWidth="600"
                    [showCloseButton]="false"
                    padding="0"
                >
                    <ng-template>
                        <p-panel>
                            <p-header
                                ><p>
                                    <i class="pi pi-id-card"></i>
                                    {{ helper.nick }}
                                </p></p-header
                            >

                            <p>Angebotene Hilfe:</p>
                            <ul>
                                <li *ngFor="let service of helper.services">
                                    <ng-container *ngIf="service == 'shopping'"
                                        >Einkaufen</ng-container
                                    >
                                    <ng-container
                                        *ngIf="service === 'childcare'"
                                        >Kinderbetreuung</ng-container
                                    >
                                    <ng-container *ngIf="service === 'sharing'"
                                        >Dinge teilen</ng-container
                                    ><ng-container
                                        *ngIf="service === 'petsitting'"
                                        >Gassi gehen</ng-container
                                    ><ng-container
                                        *ngIf="service === 'telephone'"
                                        >Einfach mal telefonieren</ng-container
                                    >
                                </li>
                            </ul>
                            <p-footer>
                                <button
                                    pButton
                                    icon="pi pi-envelope"
                                    label="Email schreiben"
                                    (click)="sendMail(helper.email)"
                                    *ngIf="helper.email"
                                ></button
                                >&nbsp;
                                <button
                                    pButton
                                    icon="pi pi-bell"
                                    label="Anrufen"
                                    (click)="callPhone(helper.phone)"
                                    *ngIf="helper.phone"
                                ></button> </p-footer
                        ></p-panel>
                    </ng-template>
                </agm-snazzy-info-window> </agm-marker></agm-marker-cluster>
</agm-map>
