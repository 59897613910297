import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '../state.service';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cna-options-check-box-bar',
    templateUrl: './options-check-box-bar.component.html',
    styleUrls: ['./options-check-box-bar.component.css']
})
export class OptionsCheckBoxBarComponent implements OnInit, OnDestroy {
    constructor(private state: StateService) {}
    options = this.state.optionsForHelp;
    destroy$ = new Subject<void>();
    form = new FormControl(this.state.optionsForHelp.map(val => val.value));
    ngOnInit(): void {
        this.form.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(val =>
                this.state.selectedOptionsForHelperFilter.next(val)
            );
    }
    ngOnDestroy(): void {
        this.destroy$.next();
    }
}
