import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../../../apps/ulm-hilft/frontend/ulm-hilft-angular/src/environments/environment';
import { switchMap, map, debounceTime, startWith } from 'rxjs/operators';
import { StateService } from '../state.service';

export interface HeremapResultItem {
    address: {
        label: string;
    };
    position: {
        lat: number;
        lng: number;
    };
}

interface HeremapsAPIResponse {
    items: HeremapResultItem[];
}

@Component({
    selector: 'cna-geo-search-bar',
    templateUrl: './geo-search-bar.component.html',
    styleUrls: ['./geo-search-bar.component.scss']
})
export class GeoSearchBarComponent implements OnInit {
    results: Observable<HeremapResultItem[]>;
    searchterm: FormControl;
    @Input() searchPlaceholder: string;
    @Output() pointSelected = new EventEmitter<HeremapResultItem>();

    constructor(private http: HttpClient, private state: StateService) {
        this.searchterm = new FormControl('');
    }

    ngOnInit(): void {
        this.results = this.searchterm.valueChanges.pipe(
            startWith(''),
            map(val => (val ? val : 'Ulm')),
            debounceTime(200),
            switchMap(searchTerm =>
                this.http.get<HeremapsAPIResponse>(
                    `https://discover.search.hereapi.com/v1/discover?in=circle:${environment.location.lat},${environment.location.lng};r=40000&q=${searchTerm}&limit=5&apiKey=${environment.hereMapsKey}`
                )
            ),
            map(results => results.items)
        );
    }

    setCoordinates(val: HeremapResultItem) {
        this.pointSelected.emit(val);
    }
}
