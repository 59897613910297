<div style="padding: 1rem 0 ">
    <p>
        Helfer anzeigen für&nbsp;&nbsp;
        <span *ngFor="let option of options" style="padding-right: 1rem">
            <p-checkbox
                [formControl]="form"
                [value]="option.value"
                [label]="option.label"
            >
            </p-checkbox>
        </span>
    </p>
</div>
