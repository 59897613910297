import { Injectable } from '@angular/core';
import { StateService } from './state.service';
import { Helper } from '@cna/projects/ulm-hilft/shared-interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../../apps/ulm-hilft/frontend/ulm-hilft-angular/src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private sate: StateService, private http: HttpClient) {}
    get(): Observable<Helper[]> {
        return this.http.get<Helper[]>(`${environment.apiUrl}/helpers`);
    }
    getMe(): Observable<Helper> {
        return this.http
            .get<Helper[]>(`${environment.apiUrl}/me`)
            .pipe(map(val => val[0]));
    }
    deleteMe() {
        return this.http.delete(`${environment.apiUrl}/me`);
    }
    addOrEdit(newValues: Helper) {
        return this.http.post<Helper>(`${environment.apiUrl}/me`, newValues);
    }
}
