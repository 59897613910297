import {
    AfterContentInit,
    AfterViewInit,
    Component,
    OnInit
} from '@angular/core';
import { AuthService } from '../../../../auth/src/lib/auth.service';
import { MessageService, SelectItem } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Helper } from '@cna/projects/ulm-hilft/shared-interfaces';
import { ApiService } from '../api.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { StateService } from '../state.service';

@Component({
    selector: 'cna-helfer-werden',
    templateUrl: './helfer-werden.component.html',
    styleUrls: ['./helfer-werden.component.css'],
    providers: [MessageService]
})
export class HelferWerdenComponent implements OnInit {
    options: SelectItem[];
    form = new FormGroup({
        email: new FormControl(''),
        nick: new FormControl(''),
        services: new FormControl(''),
        phone: new FormControl(''),
        privacy: new FormControl('', Validators.required)
    });
    location: { lat: number; lng: number };
    delete: boolean;
    constructor(
        public auth: AuthService,
        private api: ApiService,
        private messageService: MessageService,
        private state: StateService
    ) {
        this.options = this.state.optionsForHelp;
    }

    ngOnInit(): void {
        this.updateFormFromAPI();
    }
    updateFormFromAPI() {
        this.api
            .getMe()
            .pipe(tap(val => (this.delete = val === null || val === undefined)))
            .subscribe(val => {
                console.log(val);
                this.form.patchValue(val);
            });
    }
    onDelete() {
        this.api.deleteMe().subscribe(() => {
            this.messageService.add({
                severity: 'success',
                summary: 'Erfolg',
                detail: 'Daten gelöscht!'
            });
            this.form.reset();
        });
    }
    onSubmit() {
        const newHelper: Helper = Object.assign(
            { location: this.location },
            this.form.value
        );
        this.api
            .addOrEdit(newHelper)
            .pipe(
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Fehler',
                        detail: 'Das hat nicht geklaptt. 😰'
                    });
                    return throwError('Fehler beim anlegen oder bearbeiten!');
                })
            )
            .subscribe(val =>
                this.messageService.add({
                    severity: 'success',
                    summary: 'Erfolg',
                    detail: 'Helfer wurde erfolgreich gespeichert!'
                })
            );
    }
}
