import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HelferWerdenComponent } from './helfer-werden/helfer-werden.component';
import { MapComponent } from './map/map.component';
import { StartComponent } from './start/start.component';
import { GMapModule } from 'primeng/gmap';
import { GeoSearchBarComponent } from './geo-search-bar/geo-search-bar.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { environment } from '../../../../../../../apps/ulm-hilft/frontend/ulm-hilft-angular/src/environments/environment';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { ProjectsUlmHilftFrontendAuthModule } from '@cna/projects/ulm-hilft/frontend/auth';
import { CheckboxModule } from 'primeng/checkbox';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RouterModule } from '@angular/router';
import { OptionsCheckBoxBarComponent } from './options-check-box-bar/options-check-box-bar.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';


@NgModule({
    imports: [
        CommonModule,
        GMapModule,
        AutoCompleteModule,
        ReactiveFormsModule,
        HttpClientModule,
        AgmCoreModule.forRoot({ apiKey: environment.googleMapsKey }),

        AgmSnazzyInfoWindowModule,
        PanelModule,
        ButtonModule,
        CardModule,
        InputTextModule,
        SelectButtonModule,
        ToastModule,
        ProjectsUlmHilftFrontendAuthModule,
        CheckboxModule,
        RouterModule,
        AgmJsMarkerClustererModule
    ],
    exports: [MapComponent],
    declarations: [
        HelferWerdenComponent,
        MapComponent,
        StartComponent,
        GeoSearchBarComponent,
        ImprintComponent,
        PrivacyComponent,
        OptionsCheckBoxBarComponent
    ]
})
export class ProjectsUlmHilftFrontendMainModule {}
