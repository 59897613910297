import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Helper, Service } from '@cna/projects/ulm-hilft/shared-interfaces';
import { map } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';
import { environment } from '../../../../../../../apps/ulm-hilft/frontend/ulm-hilft-angular/src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StateService {
    currentCoordinates: BehaviorSubject<{ lat: number; lng: number }>;
    currentHelpers: BehaviorSubject<Helper[]>;
    optionsForHelp: SelectItem[] = [
        {
            label: 'Einkauf',
            value: 'shopping'
        },
        {
            label: 'Kinderbetreuung',
            value: 'childcare'
        },
        {
            label: 'Dinge teilen',
            value: 'sharing'
        },
        {
            label: 'Gassi gehen',
            value: 'petsitting'
        },
        {
            label: 'Telefonieren',
            value: 'telephone'
        }
    ];
    selectedOptionsForHelperFilter: BehaviorSubject<Service[]>;
    zoom: BehaviorSubject<number>;

    constructor() {
        this.currentCoordinates = new BehaviorSubject<{
            lat: number;
            lng: number;
        }>({ lat: environment.location.lat, lng: environment.location.lng });
        this.currentHelpers = new BehaviorSubject<Helper[]>([]);
        this.selectedOptionsForHelperFilter = new BehaviorSubject<Service[]>(
            this.optionsForHelp.map(val => val.value)
        );
        this.zoom = new BehaviorSubject<number>(11);
    }
}
