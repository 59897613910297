<div class="content-wrapper">
    <h2>Impressum</h2>
    <p>Gesetzliche Anbieterkennung:</p>
    <p>Badeparadies Schwarzwald TN GmbH<br /> diese vertreten durch den Gesch&auml;ftsf&uuml;hrer Edelfried Balle</p>
    <p>Am Badeparadies 1<br /> 79822 Titisee-Neustadt<br /> Deutschland</p>
    <p>Telefon: 008000 4444 333<br /> Telefax: 07651 9360-122<br /> E-Mail: shop@badeparadies-schwarzwald.de</p>
    <p>USt-IdNr.: DE 267883365<br /> eingetragen im Handelsregister des Amtsgerichtes Freiburg<br /> Handelsregisternummer HRB 704491</p>
    <p>Alternative Streitbeilegung:<br /> Die Europ&auml;ische Kommission stellt eine Plattform f&uuml;r die au&szlig;ergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a>.</p>
    <p>Wir sind bereit, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.</p>
    <p>Wir sind seit 10.01.2016 Mitglied der Initiative "FairCommerce".<br /> N&auml;here Informationen hierzu finden Sie unter <a href="https://www.fair-commerce.de/">www.fair-commerce.de</a>.</p>
    <p>Realisierung:</p>
    <p>rrooaarr interactive solutions<br /> H&auml;mpfergasse 15<br /> 89073 Ulm<br /> Deutschland<br /> <a href="https://www.rrooaarr.com">www.rrooaarr.com</a></p>
    <p>&nbsp;</p>
</div>
