import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '../state.service';
import { combineLatest, interval, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { MapTypeStyle } from '@agm/core';
import { ApiService } from '../api.service';
import { Helper } from '@cna/projects/ulm-hilft/shared-interfaces';

@Component({
    selector: 'cna-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnDestroy {
    overlays: any;

    destroy$ = new Subject<void>();
    styles: MapTypeStyle[] = [
        {
            featureType: 'road',
            stylers: [{ color: '#FFE438' }],
            elementType: 'geometry.fill'
        },
        { featureType: 'poi', stylers: [{ visibility: 'off' }] }
    ];
    currentHelpersWithLocation: Observable<Helper[]> = combineLatest([
        this.state.currentHelpers.pipe(
            map(helpers => helpers.filter(helper => 'location' in helper))
        ),
        this.state.selectedOptionsForHelperFilter
    ]).pipe(
        tap(val => console.log(val)),
        map(([helpers, helperfilter]) =>
            helpers.filter(
                helper =>
                    helper.services.filter(val => helperfilter.includes(val))
                        .length > 0
            )
        )
    );

    constructor(public state: StateService, private api: ApiService) {
        interval(1000)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() =>
                this.api.get().pipe(
                    withLatestFrom(this.state.currentHelpers),
                    map(([newHelpers, currentHelpers]) =>
                        newHelpers !== currentHelpers
                            ? this.state.currentHelpers.next(newHelpers)
                            : undefined
                    )
                )
            );
    }
    ngOnInit(): void {
        this.state.currentCoordinates
            .pipe(
                takeUntil(this.destroy$),
                tap(val => console.log(val))
            )
            .subscribe(val => console.log(val));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    sendMail(email: string) {
        window.location.href = `mailto:${email}`;
    }
    callPhone(number: string) {
        window.location.href = `tel:${number}`;
    }
}
