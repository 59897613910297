<span class="ui-fluid">
    <p-autoComplete
        [formControl]="searchterm"
        [suggestions]="results | async"
        styleClass="wid100"
        [showTransitionOptions]="'200ms'"
        [hideTransitionOptions]="'200ms'"
        [dropdown]="true"
        [placeholder]="searchPlaceholder"
        (onSelect)="setCoordinates($event)"
        field="address.label"
        class="auto-complete"
    >
    </p-autoComplete>
</span>
