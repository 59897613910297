import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cna-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
