import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginButtonComponent } from './login-button/login-button.component';
import { SplitButtonModule } from 'primeng/splitbutton';

@NgModule({
    imports: [CommonModule, SplitButtonModule],
    exports: [LoginButtonComponent],
    declarations: [LoginButtonComponent]
})
export class ProjectsUlmHilftFrontendAuthModule {}
